import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "Header"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"color": "--primary",
			"sm-text-align": "center",
			"children": "Taskmasters"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "--darkL2",
			"sm-text-align": "center",
			"children": "Business Administration and Accounting in Cyprus"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "24px",
			"align-items": "center"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"border-radius": "12px",
			"color": "--primary",
			"margin": "0px 24px 0px 0px",
			"href": "/services",
			"children": "Services"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"type": "link",
			"text-decoration-line": "initial",
			"href": "tel:+35797698328",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"border-radius": "12px",
			"color": "--primary",
			"background": "rgba(22, 163, 74, 0.1)"
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"type": "link",
			"text-decoration-line": "initial",
			"href": "mailto:olga.yatsenko@taskmasters.ltd?I have a question",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"border-radius": "12px",
			"color": "white"
		}
	}
};

const Header3 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			justify-content="space-between"
			align-items="center"
			sm-flex-direction="column"
			sm-display="flex"
			sm-grid-gap="24px"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
			<Text {...override("text1")} />
		</Box>
		<Box {...override("box1")}>
			<Link {...override("link")} />
			<Button {...override("button")}>
				Phone
			</Button>
			<Button {...override("button1")}>
				Email
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header3, { ...Section,
	defaultProps,
	overrides
});
export default Header3;