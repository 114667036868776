import React, { useState } from 'react';
import atomize from '@quarkly/atomize';
import { Box, Button } from '@quarkly/widgets';
import { useOverrides } from '@quarkly/components';
const overrides = {
	Content: {
		kind: 'Box'
	},
	Column: {
		kind: 'Column'
	},
	Cell: {
		kind: 'Cell',
		props: {
			padding: '0.4rem 1rem',
			border: '1px solid #E4E8EC'
		}
	},
	Text: {
		kind: 'Text'
	},
	'More Button': {
		kind: 'Button',
		props: {
			children: 'Show more'
		}
	},
	'Less Button': {
		kind: 'Button',
		props: {
			children: 'Show less'
		}
	}
};

function ShowMore({
	once,
	...props
}) {
	const {
		override,
		ChildPlaceholder,
		rest
	} = useOverrides(props, overrides);
	const [isMore, setIsMore] = useState(false);
	return <div {...rest}>
		      
		<Box {...override('Content')}>
			        
			<ChildPlaceholder slot="Content" />
			      
		</Box>
		      
		{isMore ? <Box {...override('MoreContent')}>
			          
			<ChildPlaceholder slot="MoreContent" />
			        
		</Box> : <Box {...override('MoreContentPlaceholder')}>
			          
			<ChildPlaceholder slot="MoreContentPlaceholder" />
			        
		</Box>}
		      
		{!(once && isMore) && <Button onClick={() => setIsMore(x => !x)} {...override('Button', isMore ? 'Less Button' : 'More Button')} />}
		    
	</div>;
}

Object.assign(ShowMore, {
	propInfo: {
		once: {
			control: 'checkbox'
		}
	},
	defaultProps: {
		once: false
	}
});
export default ShowMore;