import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Image, LinkBox, Button, Section } from "@quarkly/widgets";
const defaultProps = {
	"quarkly-title": "Footer"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--headline3",
			"color": "--darkL2",
			"sm-text-align": "center",
			"children": "Get in Touch"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "24px",
			"align-items": "center",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"padding": "8px 24px 8px 12px",
			"border-width": "0 1px 0 0",
			"border-style": "solid",
			"border-color": "--color-light",
			"md-padding": "8px 24px 8px 0px",
			"sm-border-width": "0px"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"grid-gap": "8px",
			"align-items": "center",
			"justify-content": "flex-start",
			"href": "https://linkedin.com/in/olga-yatsenko-25859566",
			"target": "_blank"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/taskmasters-linkedin-logo.svg?v=2024-01-05T12:09:59.505Z",
			"display": "block"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-decoration-line": "initial",
			"color": "--primary",
			"font": "normal 500 16px/1.5 --fontFamily-sans",
			"children": "LinkedIn"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"type": "link",
			"text-decoration-line": "initial",
			"href": "tel:+35797698328",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"border-radius": "12px",
			"color": "--primary",
			"background": "rgba(22, 163, 74, 0.1)"
		}
	},
	"button1": {
		"kind": "Button",
		"props": {
			"type": "link",
			"text-decoration-line": "initial",
			"href": "mailto:olga.yatsenko@taskmasters.ltd?I have a question",
			"font": "normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
			"border-radius": "12px",
			"color": "white"
		}
	}
};

const Footer2 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			flex-direction="row"
			justify-content="space-between"
			align-items="center"
			sm-flex-direction="column"
			sm-display="flex"
			sm-grid-gap="24px"
			lg-grid-gap="16px"
			background="--color-lightD1"
			border-radius="40px"
			padding="40px 40px 40px 40px"
			sm-padding="30px 20px 30px 20px"
			sm-border-radius="20px"
			md-flex-direction="column"
			md-align-items="flex-start"
		/>
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<Box {...override("box2")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
					<Text {...override("text1")} />
				</LinkBox>
			</Box>
			<Button {...override("button")}>
				Phone
			</Button>
			<Button {...override("button1")}>
				Email
			</Button>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer2, { ...Section,
	defaultProps,
	overrides
});
export default Footer2;